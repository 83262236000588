body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.pageMeta{
  text-align: center;
}


.logo{
  height: 30px;
  width: auto;
}

.nameBox{
  display: inline-block;
}

.frontLogo{
  width: 300px;
}


.loginForm{
  text-align: center;
  margin: 200px auto 0;
  width: 300px;
}

.editSubmission textarea.form-control, .editSubmission textarea.form-control{
  height: 120px;
}
.editSubmission textarea.form-control.larger, .editSubmission textarea.form-control.larger{
  height: 260px;
}

.submissionForm textarea.form-control, .submissionForm textarea.form-control{
  height: 120px;
}
.submissionForm textarea.form-control.larger, .submissionForm textarea.form-control.larger{
  height: 260px;
}
